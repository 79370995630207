.sdc_container {
  border: 1px solid var(--grayColor);
  border-radius: 8px;
  padding: 15px;
  display: flex;
  gap: 30px;
  max-width: 544px;
  cursor: pointer;
}

.sdc_img_container {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.sdc_right_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sdc_device_image {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.sdc_see_map {
  margin-top: 2px;
  color: var(--bamLightBlue);
  text-underline-offset: 2px;
  text-decoration: underline;
}
