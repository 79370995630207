.snackbar_main_container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.snackbar {
    visibility: hidden;
    max-width: 600px;
    width: 100%;
    background-color: var(--redColor);
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 10px;
    position: fixed;
    z-index: 9999;
    top: 0;
    transform: translateX(-50%);
    transition: visibility 0s, top 0.3s;
  }
  
  .snackbar_success {
    background-color: var(--darkGreenColor);
  }

  .snackbar_text {
    color: var(---white) !important;
    text-align: left !important;
  }

  .snackbar.show {
    visibility: visible;
    top: 0px; /* Adjust this value as needed */
  }