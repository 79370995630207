.personal_details_section {
  background-color: var(--grayColor);
}

.emergency_text_container {
  width: 100%;
  background-color: var(--grayColor);
  padding: 15px;
  gap: 20px;
}

.ep_input_label {
  color: var(--blackText);
  text-align: left;
}

.ep_consent_box {
  height: 200px;
  overflow-y: auto;
  border: 2px solid var(--grayColor);
  border-radius: 8px;
  padding: 15px;
}

.lock_box_code_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lock_box_code_input_container {
  width: 40%;
  position: relative;
}

.lock_box_code_input:disabled {
  background-color: var(--grayColor);
  cursor: not-allowed;
}

.lock_box_code_input {
  position: relative;
  font-size: 17px;
}

.lock_input_note_icon {
  position: absolute;
  top: 12px;
  right: 10px;
  cursor: pointer;
}

.radio_container {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.ep_radio {
  zoom: 1.5;
  accent-color: var(--bamLightBlue);
}

.ep_placeholder_style {
  top: 33px;
}

.ep_placeholder_style_characters {
  top: 45px;
}

.lock_helpful_icon svg {
  margin-bottom: 9px;
}