.as_version {
  color: var(--whiteBoldText) !important;
}

.version_value {
  color: var(--whiteBoldText) !important;
  text-align: left !important;
}

.as_copyright_text {
  text-align: left !important;
  color: var(--grayColor) !important;
}

.as_bam_text {
  margin-top: 25px;
}
