/* breakpoints defined */
/* Mobile - 320px to 576px */
/* Tablet - 577px to 1024px */
/* Laptop - 768px bigger */

p,
h3 {
  margin: 0px;
}

h1 {
  word-wrap: break-word; /* Break long words onto the next line */
  word-break: break-word; /* Ensure long words are broken */
  white-space: normal;
}

:root {
  --white: #ffffff;
  --blackColor: #000000;
  --whiteBoldText: #f3f4f6;
  --buttonBg: #248CA7;
  --blackText: #242a34;
  --screenBGColor: #1f2531;
  --grayColor: #bebebe;
  --placeholderTextColor: #6b6b6b;
  --bamLightBlue: #248ca7;
  --redColor: #c31514;
  --greenColor: #34e525;
  --darkGreenColor: #026440;
  --blackText: #242a34;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.screen_common_container_style {
  margin: 0 auto;
  width: 100%;
  padding: 30px 2rem 100px;
}

.common_desktop_layout {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 20px;
  padding-bottom: 150px;
  width: 100%;
}

.gm-inset-map,
.gm-inset-light,
.gmnoprint,
.gm-bundled-control,
.gm-bundled-control-on-bottom {
  display: none !important;
}

.left {
  text-align: left !important;
}

.text-white-color {
  color: var(--white) !important;
}

.text-center {
  text-align: center !important;
}

.pb100 {
  padding-bottom: 100px;
}

.relative {
  position: relative;
}

.mr5 {
  margin-right: 5px;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb18 {
  margin-bottom: 18px;
}

.mb22 {
  margin-bottom: 22px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb38 {
  margin-bottom: 38px;
}

.main_container {
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  width: 5px;
  z-index: 100;
}

::-webkit-scrollbar-thumb {
  background-color: var(--grayColor);
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track-color);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--grayColor);
}

.display_h2 {
  font-family: Circular TT;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: var(--blackText);
}

.display_h3 {
  font-family: Circular TT;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: var(--blackText);
}

.display_h4 {
  font-family: Circular TT;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
}

.display_h5 {
  font-family: Circular TT;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  color: var(--blackText);
}

.display_h6 {
  font-family: Circular TT;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  text-align: left;
}

.body_h1 {
  font-family: Circular TT Medium;
  font-size: 14px;
  font-weight: 450;
  line-height: 18px;
  color: var(--blackText);
}

.body_h2 {
  font-family: Circular TT Book;
  font-size: 12px;
  font-weight: 450;
  line-height: 16px;
  text-align: center;
  color: var(--blackText);
}

.body_h3 {
  font-family: Circular TT Medium;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
}

.web_link {
  font-family: Circular TT Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: var(--bamLightBlue);
  text-underline-offset: 5px;
}

.blue_button {
  background-color: var(--buttonBg) !important;
  line-height: 22px !important;
}

.common_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 544px;
  margin: 0 auto;
}

.center {
  text-align: center !important;
}

.h60 {
  height: calc(100vh - 60px);
}

.h160 {
  height: calc(100vh - 110px);
}

.h100 {
  height: 100vh;
}

.horizontal_divider_line {
  border-top: 1px solid var(--placeholderTextColor);
  height: 1px;
}

.accordian_btn_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
  padding: 5px;
  cursor: pointer;
}

.new_line_text {
  white-space: pre-line;
}

.view_disabled_curser {
  cursor: not-allowed;
}

.view_enable_curser {
  cursor: pointer;
}