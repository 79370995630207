.otp_container_div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.otp_input {
  width: 40px;
  height: 40px;
  margin: 0 5px;
  font-size: 24px;
  text-align: center;
  border: 2px solid var(--grayColor);
}

.otp_input:focus {
  outline: none;
  border-color: var(--blackColor);
}

.otp_body {
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0px 6px 6px 0px #00000040;
  display: flex;
  flex-direction: column;
  padding: 15px !important;
  position: relative;
}

.otp_cross_icon {
  position: absolute;
  top: -20px;
  left: -20px;
  border-radius: 50%;
  cursor: pointer;
}

.otp_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .modal-content {
    max-width: 70%;
    margin: 0 auto;
  }
}

.otp_text {
  text-align: center !important;
  color: var(--bamLightBlue) !important;
}

.otp_confirm_btn {
  color: var(--whiteBoldText) !important;
  background-color: var(--blackColor) !important;
}

.otp_expire_text {
  margin-top: 10px;
}

.resend_code_text {
  text-decoration: underline;
  text-underline-offset: 5px;
  cursor: pointer;
}

@media screen and (max-width: 576px) {
  .otp_container {
    .modal-content {
      max-width: 90%;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 370px) {
  .otp_input {
    width: 30px;
    height: 30px;
    margin: 0 3px;
    font-size: 20px;
  }
}
