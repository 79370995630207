.location_history {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.location_history_icon {
  margin-left: 10px;
}

.location_history_icon:hover {
  border-color: #555;
}

.location_history_calendar_div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.location_history_lt_map_container {
  height: calc(100vh - 155px);
  margin-top: 10px;
}

.gm-control-active .gm-fullscreen-control {
  display: none !important;
}

.location_history_map_div {
  width: 100%;
  height: 100%;
}

.gm-style-iw-ch {
  display: none;
}

.location_history_text_styles {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: white;
}

.location_history_text_div {
  padding-top: 30px;
  text-align: center;
}

.gm-style-iw-chr {
  display: none;
}

.svg_div {
  height: 16px;
}
