.admin_sign_in_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.logo_bam_std_container {
  margin-bottom: 40px;
  width: 200px;
  height: 62.79px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.intro_header_text {
  text-align: center !important;
  margin-bottom: 40px;
  color: var(--white);
}

.guest_signin_btn {
  margin-bottom: 40px;
}

.copyright_text {
  color: var(--grayColor) !important;
}

.admin_sign_in_btn {
  margin-bottom: 40px;
}
