.sd_label_text {
  color: var(--bamLightBlue) !important;
  text-align: left !important;
}

.sd_notify_icon {
  cursor: pointer;
  height: 15px;
  width: 15px;
}

.sd_notify_icon svg {
  margin-bottom: 3px;
}

.sd_shipping_title_container {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  gap: 10px;
}

.sd_submit_btn {
  background-color: var(--bamLightBlue) !important;
  color: var(--white) !important;
  border: none;
  margin: 0 auto;
  display: block;
}

.esd_submit_btn {
  background-color: var(--bamLightBlue);
  color: var(--white);
  border: none;
  margin: 0 auto;
  display: block;
  max-width: 520px;
}

.sd_fields_container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
