.home_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0px 6rem;
}

@media screen and (max-width: 576px) {
  .home_container {
    padding: 2rem 2rem !important;
  }
}
