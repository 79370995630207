.approval_needed_text {
    font-size: 22px;
    font-weight: 700;
    font-family: Circular TT Bold;
    text-align: left;
    color: #248CA7;
    margin: 0px;
    margin-bottom: 15px;
}

.signature_need_desc {
    font-size: 16px;
    font-weight: 400;
    font-family: Circular TT Medium;
    text-align: left;
    margin: 0px;
    color: #242A34;
}

.active_subscription_changes {
    margin-top: 20px;
    font-weight: 600;
}

.account_holder_info_changes {
    font-weight: 500;
    margin-bottom: 10px !important;
}

.account_holder_card_container {
    padding-top: 24px;
    padding-bottom: 24px;
    border-radius: 8px;
    border: 2px solid #BEBEBE;
    margin-top: 25;
    align-items: center;
}

.service_name {
    color: #248ca7;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 7px;
    font-family: Circular TT Bold;
}