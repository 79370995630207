.ns_notification_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.ns_text {
  color: var(--whiteBoldText) !important;
}

.ns_h_line {
  border-top: 1px solid var(--grayColor);
  height: 1px;
  margin: 30px 0px;
}

.form-check-input {
  background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27%23fff%27/></svg>") !important;
  background-color: var(--grayColor) !important;
  border-color: var(--grayColor) !important;
}
.form-check-input:checked {
  background-color: var(--buttonBg) !important;
  border-color: var(--buttonBg) !important;
  background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27%23fff%27/></svg>") !important;
}

.form-check-input:focus {
  border-color: var(--buttonBg) !important;
  outline: 0;
  box-shadow: none !important;
}