.mg_notify_text {
  color: var(--whiteBoldText) !important;
  text-align: left !important;
}

.mg_tools {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: 80%;
  padding-right: 20%;
  margin-bottom: 5px;
}

.mg_card_container {
  border: 2px solid var(--grayColor);
  border-radius: 8px;
  padding: 15px;
}

.add_guest_user_text {
  color: var(--buttonBg) !important;
  cursor: pointer;
}

.mg_icon {
  cursor: pointer;
}

.mg_horizontal_line {
  border-top: 1px solid var(--grayColor) !important;
  height: 1px;
  margin: 30px 0px;
}

.mg_tip_left {
  margin-left: 2px;
}