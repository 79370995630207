.para_card_container {
  width: 100%;
  max-width: 544px;
  border-radius: 10px;
  border: 2px solid var(--grayColor);
  padding: 18px;
}

.pc_title_text {
  text-align: left !important;
}

.pc_value_text {
  color: var(--bamLightBlue);
}

.pc_section_div {
  display: flex;
  justify-content: space-between;
}

.pc_card_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto 0px;
}
