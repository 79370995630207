.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox .checkmark {
  width: 20px;
  height: 20px;
  border: 2px solid gray;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-checkbox input[type="checkbox"]:checked + .checkmark::after {
  content: "";
  width: 6px;
  height: 12px;
  border: solid var(--placeholderTextColor);
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
