/* Container for the progress circle */
.progress-container {
  position: relative;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Background circle */
.circle-background {
  fill: none;
  stroke: #e0e0e0; /* Background color for the progress bar */
  stroke-width: 10;
}

/* Progress circle */
.circle-progress {
  fill: none;
  stroke: var(--bamLightBlue); /* Progress bar color */
  stroke-width: 10;
  stroke-dasharray: 440; /* 2 * PI * 70 (radius) */
  stroke-dashoffset: 440; /* Start point of the progress */
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  animation: progress-animation 5s linear forwards;
}

.ring_device_image {
  width: 100px;
  height: 100px;
  margin-top: 15px;
}

/* Animation keyframes */
@keyframes progress-animation {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 440;
  }
}
