.payment_card_container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.bd_submit_btn {
  max-width: 520px !important;
  background-color: var(--bamLightBlue) !important;
  color: var(--white) !important;
  border: none;
  margin: 0 auto;
  display: block;
}

.bd_placeholder_style {
  top: 12px;
}

.cvv_input_container {
  position: relative;
}

.bd_que_icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
