.cs_end_contract_text {
  color: var(--whiteBoldText) !important;
  text-align: left !important;
}

.cs_non_passing_text {
  color: var(--whiteBoldText) !important;
  text-align: left !important;
  margin: 15px 0px;
}

.cs_contact_us_link {
  text-underline-offset: 5px;
}

.cs_upload_doc_link {
  color: var(--buttonBg) !important;
  display: block !important;
  text-underline-offset: 5px;
  text-align: left !important;
  margin: 15px 0px;
}

.cs_submit_btn {
  background-color: var(--bamLightBlue) !important;
  color: var(--white) !important;
  display: block;
  margin: 20px auto;
}
