.acp_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.acp_current_pwd {
  margin-bottom: 45px;
}

.acp_header_text {
  text-align: left !important;
  font-weight: 100 !important;
  color: var(--whiteBoldText) !important;
  align-self: start;
  width: 100%;
  text-align: center !important;
}

.cp_placeholder_style {
  top: 35px !important;
}
