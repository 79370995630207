.custom_select_box_container {
  width: 100%;
  max-width: 544px;
  border-radius: 3px;
  border: 1px solid var(--placeholderTextColor);
  height: 45px;
}
.cs_box_button:disabled {
  background-color: var(--grayColor) !important;
  color: var(--white);
  cursor: not-allowed;
}

.cs_box_button {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 10px;
  background-color: var(--white);
  border: none;
  display: flex;
  align-items: center;
}

.cs_dropdown_icon_down {
  transition: transform 1ms ease-in-out;
  position: absolute;
  top: 7px;
  right: 10px;
}

.cs_dropdown_icon_up {
  transition: transform 1ms ease-in-out;
  position: absolute;
  top: 10px;
  right: 10px;
}

.rotate_180 {
  transform: rotate(180deg);
}

.dropdown_page {
  position: absolute;
  top: 3rem;
  left: -1px;
  background-color: var(--white);
  width: 100%;
  max-height: 13rem;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  background-color: var(--white);
  border: 0.5px solid var(--placeholderTextColor);
}

.cs_list_container {
  line-height: 2.5rem;
  padding-left: 0px !important;
}

.cs_list {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  text-align: left !important;
  line-height: 2.25rem !important;
  color: var(--placeholderTextColor) !important;
}

.cs_list:hover {
  background-color: lightgray;
}

.cs_list.selected {
  background-color: var(--grayColor);
}

.csb_placeholder_text {
  color: var(--placeholderTextColor) !important;
}
