.select_modal_container {
  max-height: 250px;
  overflow-y: auto;
}

.custom_select_with_modal_container {
  position: relative;
}

.custom_select_box {
  height: 45px;
  border-radius: 3px;
  border: 1px solid var(--placeholderTextColor);
  background-color: var(--white);
  padding: 12px;
}

.custom_select_box_icon {
  position: absolute;
  top: 10px;
  right: 16px;
}

.dropdown_option {
  padding: 5px 15px;
  cursor: pointer !important;
  color: var(--placeholderTextColor) !important;
  text-align: left !important;
}

.selected_option {
  background-color: var(--blackColor);
  color: var(--white) !important;
}

.custon_select_modal_body {
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0px 6px 6px 0px #00000040;
  display: flex;
  flex-direction: column;
  padding: 10px 0px !important;
  position: relative;
}

.error_text {
  color: var(--redColor) !important;
}

.cs_placeholder_text {
  color: var(--placeholderTextColor);
}
