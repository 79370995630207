.dp_img_position {
  top: 7% !important;
}

.dp_card_link {
  display: block;
}

.dp_card_last_refresh {
  text-align: center !important;
}

.caregiver_card_container {
  width: 100%;
  max-width: 544px;
  border-radius: 10px;
  min-height: 150px;
  border: 2px solid var(--grayColor);
}

.caregiver_card_btn {
  background-color: var(--blackText);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto 2px;
}

.button_div {
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dp_battery_btn {
  margin: 15px 0px;
}

.caregiver_card_title {
  text-align: left !important;
  padding: 20px;
}

.dp_container {
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dp_title_container {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 40px;
  margin-left: 55px;
}

.dp_card_edit_icon {
  cursor: pointer;
}

.dp_btn_container {
  width: 100%;
  max-width: 544px;
}

.map_container {
  position: relative;
  height: 150px;
}

.loader_text {
  text-align: center;
  position: absolute;
  top: 26%;
  left: 14%;
}

.refresh_signal_button:disabled {
  background-color: var(--white);
  border: solid 1px var(--grayColor);
  color: var(--grayColor);
}

.empty_map {
  height: 150px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.dp_time_container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.dp_time_center {
  justify-content: center;
}

.map_info_left {
  margin-left: 20px;
  margin-top: -15px;
  margin-bottom: 20px;
}