.nb_tabname {
  color: var(--whiteBoldText);
}

.smalldevice_navbar_container {
  display: flex;
  justify-content: center;
  gap: 1.6rem;
  position: sticky;
  bottom: 0px;
  margin: 0 auto;
  width: 100%;
  z-index: 999;
  background-color: var(--blackColor);
  padding-top: 5px;
}

.nb_small_tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  gap: 4px;
}

.tab_image {
  width: 25px;
  height: 25px;
  margin-top: 1px;
}

@media screen and (min-width: 768px) {
  .smalldevice_navbar_container {
    max-width: 600px;
    border-left: solid 1px var(--grayColor);
    border-right: solid 1px var(--grayColor);
  }
}

.tab_notif_count {
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: red;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -5px;
  right: -1px;
}