.ah_add_contact_text {
  color: var(--bamLightBlue) !important;
  text-align: left !important;
  cursor: pointer;
  width: fit-content;
}

.ah_phone_container {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  background-color: var(--grayColor);
  padding: 15px;
  gap: 20px;
}

.flex_item2 {
  flex-basis: 70%;
}

.ah_image_position {
  top: 16% !important;
}

.acc_holder_placeholder {
  top: 12px !important;
}

.ah_input_style:focus {
  border: 1px solid var(--placeholderTextColor);
}

.field_title_container {
  display: flex;
  flex-direction: row;
}