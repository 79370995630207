.sp_container {
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sp_title_container {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 15px;
}

.acc_status_text {
  text-align: center !important;
}

.sp_device_tracking_card {
  background-color: var(--blackColor);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: 544px;
}

.sp_tracking_text {
  color: var(--whiteBoldText) !important;
}

.sp_sub_detail_card {
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 544px;
  background-color: var(--whiteBoldText);
}

.sp_tittle_icon_container {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  gap: 10px;
}

.sp_que_icon {
  cursor: pointer;
  margin-left: -5px;
}

.sp_shipping_address_card {
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 544px;
  background-color: var(--white);
  border: 2px solid var(--grayColor);
}

.sp_card_detail_container {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
