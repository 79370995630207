.react-datepicker-wrapper {
  width: 100%;
}

.date_picker_container {
  position: relative;
}

.calender_icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
