.invoice_card_container {
  border: 1px solid var(--placeholderTextColor);
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  cursor: pointer;
}

.invoice_title {
  color: var(--bamLightBlue) !important;
}

.card_details_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card_values {
  color: var(--placeholderTextColor);
}

.paid {
  color: var(--darkGreenColor);
}

.unpaid {
  color: var(--redColor);
}

.checkbox_visible {
  visibility: visible;
}

.checkbox_hide {
  visibility: hidden;
}

.invoice_list_container {
  overflow-y: auto;
  overflow: hidden; /* For most browsers */
  overflow-y: scroll; /* Keep the element scrollable in the vertical direction */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.with_button {
  height: calc(100vh - 250px);
}

.without_button {
  height: calc(100vh - 196px);
}

.invoice_list_container::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
