.od_value_text {
  color: var(--grayColor);
}

.product_details_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.od_top_margin {
  margin-top: 15px;
}

.od_left_spacing {
  margin-left: 15px;
}
