.dsc_status_value {
    text-align: right;
    margin-top: 5px;
}

.dsc_margin_left {
    margin-left: 10px;
    display: flex;
    flex: 1;
    margin-top: 5px;
}

.dsc_width_full {
    width: 100%;
}

.dsc_signal_container {
    flex-direction: row;
    display: flex;
    align-items: center;
}

.dsc_title_margin_bottom {
    margin-bottom: 10px;
}

.dsc_seperator {
    margin-top: 12px;
    margin-bottom: 10px;
}

.dsc_signal_low_color {
    color: var(--redColor);
}

.dsc_no_signal_color {
    color: var(--grayColor);
}