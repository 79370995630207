.alert_cards_container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.alert_dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.alert_dot_blue_color {
  background-color: var(--buttonBg);
}

.alert_dot_white_color {
  background-color: var(--whiteBoldText);
}

.alert_text_color {
  color: var(--whiteBoldText) !important;
  text-align: left !important;
  user-select: none;
}

.alert_left_container {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
}

.card_with_buttons {
  display: flex;
  cursor: pointer;
  overflow-x: hidden;
  background-color: var(--grayColor);
  border-radius: 8px;
}

.alert_card_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid var(--bamLightBlue);
  border-radius: 8px;
  padding: 5px 1rem;
  cursor: pointer;
  width: 100%;
  min-height: 80px;
  background-color: var(--blackColor);
  transition: width 0.5s;
}

.swiped_card_effect {
  width: 90%;
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.info_bg {
  background-color: var(--grayColor);
}

.view_bg {
  background-color: var(--bamLightBlue);
}

.alert_detail_show {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  width: 70px;
}

.alert_detail_hide {
  opacity: 0;
  width: 0px;
}

.alert_text_container {
  justify-self: flex-start;
  flex: 1;
}

.alert_date_time_color {
  color: var(--grayColor) !important;
}