@font-face {
  font-family: "Circular TT";
  src: url("./CircularTT-Black.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Circular TT Book";
  src: url("./CircularTT-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Circular TT Medium";
  src: url("./CircularTT-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Circular TT Bold";
  src: url("./CircularTT-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}