.acm_checkbox_field {
  zoom: 1.3;
}

.sms_note {
  text-align: left !important;
}

.opt_out_text {
  text-decoration: underline;
  text-underline-offset: 5px;
}

.consent_note_container {
  display: flex;
  align-items: center;
  gap: 10px;
}
