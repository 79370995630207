.cb_container {
  text-align: center !important;
  border: solid 2px var(--blackColor);
  border-radius: 70px;
  background-color: var(--white);
  user-select: none;
  margin: 0 auto;
  display: block;
}

.cb_container:disabled {
  background-color: var(--grayColor);
  color: var(--white);
  cursor: not-allowed;
}

.cb_container_gray {
  background-color: var(--grayColor);
  color: var(--white);
}