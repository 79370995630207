.settings_text {
  color: var(--white) !important;
}

.settings_sign_out_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto 0px;
  background-color: var(--bamLightBlue) !important;
  color: var(--white);
  border: none !important;
}
