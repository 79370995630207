.di_body {
  background-color: var(--buttonBg);
  border-radius: 8px;
  box-shadow: 0px 6px 6px 0px #00000040;
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  position: relative;
}

.di_cross_icon {
  position: absolute;
  top: -20px;
  left: -20px;
  border-radius: 50%;
  cursor: pointer;
}

.di_value_text {
  text-align: left !important;
}

.di_container {
  .modal-content {
    max-width: 70%;
    margin: 0 auto;
  }
}
