.ahh_logo_bam_std {
  width: 150px;
  height: 45px;
  align-self: flex-start;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.account_holder_home_container {
  width: 100%;
  margin: 0 auto;
  background-color: var(--blackText);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ahh_name {
  margin-top: 2px;
}

.ahh_reviewBtn {
  color: var(--white);
  border-color: var(--blackText);
}

@media screen and (min-width: 768px) {
  .account_holder_home_container {
    max-width: 600px;
  }
}


.ahh_user_item_container {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.ahh_user_item_seperator {
  width: 100%;
  height: 1px;
  background-color: var(--grayColor);
  margin-top: 15px;
  margin-bottom: 15px;
}

.ahh_user_detail_margin {
  margin-top: 24px;
  margin-bottom: 20px;
  align-items: center;
  padding: 0px 5rem;
  width: 100%;
}

@media screen and (max-width: 576px) {
  .ahh_user_detail_margin {
    padding: 0rem 1rem !important;
  }
}

.ahh_detail_text {
  color: var(--white) !important;
  word-wrap: break-word;
  display: flex;
  flex: 1;
}

.ahh_detail_black_text {
  color: var(--blackText) !important;
}

.ahh_detail_container {
  padding-top: 5px;
  padding-bottom: 20px;
  word-wrap: break-word; /* Break long words onto the next line */
  word-break: break-word;
}

.ahh_margin_right {
  margin-right: 10px;
}

.ahh_right_text {
  text-align: right !important;
  justify-content: flex-end;
}

.tip_text_icon {
  display: inline-block;
}

.tip_text_icon svg{
  margin-bottom: 3px;
}

.tip_icon_container {
  width: 100%;
  justify-content: flex-end;
  display: flex;
  padding: 0px 5rem;
  padding-top: 5px;
}

@media screen and (max-width: 576px) {
  .tip_icon_container {
    padding: 0rem 1rem !important;
  }
}