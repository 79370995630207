.sh_container {
  height: 60px;
  background-color: var(--blackColor);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.5rem;
  position: sticky;
  top: 0px;
  z-index: 999;
  margin: 0 auto;
}

.sh_title {
  margin: 0 auto;
  text-align: center !important;
  color: var(--white);
}

.sh_backIcon {
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .sh_container {
    max-width: 600px;
    border-left: solid 1px var(--grayColor);
    border-right: solid 1px var(--grayColor);
  }
}
