.radio-inner-circle {
    height: 10px;
    width: 10px;
    border-radius: 5px;
    background-color: #000;
}

.radio-outer-circle {
    height: 20px;
    width: 20px;
    border-radius: 10px;
    border: 2px solid #000;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-right: 10px;
  }

  .custom-radio-button-container {
    flex-direction: row;
    display: flex;
    align-items: center;
    margin-bottom: 10;
    width: 100%;
  }

.custom-radio-main-container {
    flex-direction: row;
    display: flex;
    margin-bottom: 20px;
  }