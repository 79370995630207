.agu_container {
  .modal-content {
    max-width: 70%;
    margin: 0 auto;
  }
}

.agu_body {
  padding: 0px !important;
  border: 2px solid var(--grayColor) !important;
}

.agu_header {
  background-color: var(--grayColor);
  padding: 20px 20px 10px;
}

.agu_title {
  text-align: left !important;
  margin-bottom: 10px;
}

.agu_content_section {
  padding: 20px 10px;
  border-radius: 5px !important;
  background-color: var(--whiteBoldText);
}

.agu_input_label {
  text-align: left !important;
}

.agu_input_note {
  text-align: right !important;
  color: var(--placeholderTextColor);
}

.agu_holder_placeholder {
  top: 16px;
}

.agu_input_field {
  width: 100%;
  border: 1px solid var(--placeholderTextColor);
  background-color: var(--white);
  border-radius: 3px;
  height: 45px;
  padding: 1rem;
  margin-top: 5px;
  color: var(--placeholderTextColor);
}

.agu_input_field:focus {
  border: 1px solid var(--placeholderTextColor);
}

.esd_input_field:focus {
  border: 1px solid var(--placeholderTextColor);
}

.agu_card_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto 0px;
}

.agu_save_btn {
  background-color: var(--blackColor);
  color: var(--white) !important;
}

.agu_name_instruction {
  text-align: left !important;
}
