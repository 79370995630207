.hc_contact_btn {
  margin: 0px auto;
  display: block;
  margin-top: 20px;
}

.hc_color {
  color: var(--whiteBoldText) !important;
  text-align: left !important;
}

.hc_desc_container {
  width: 100%;
  max-width: 544px;
}

.feedback_desc {
  padding: 10px;
  width: 100%;
  text-align: left !important;
}

.feedback_desc_text {
  width: 100%;
}

.selected_file_name {
  background-color: var(--grayColor);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 15px 0px;
}

.hc_trash_icon {
  cursor: pointer;
}

.cs_select_dropdown {
  margin: 15px 0px;
  background-color: var(--white);
}
