.layout_container {
  overflow-y: auto;
}

.main_layout {
  position: relative;
  width: 100%;
  margin: 0px auto;
  max-width: 600px;
}

.home_header {
  position: sticky;
  top: 0px;
  width: 100%;
  max-width: 600px;
  border-left: solid 1px var(--grayColor);
  border-right: solid 1px var(--grayColor);
  background-color: var(--buttonBg);
  padding: 20px;
}

@media screen and (min-width: 768px) {
  .layout_container {
    max-width: 600px !important;
    border-left: solid 1px var(--grayColor);
    border-right: solid 1px var(--grayColor);
  }
}

.layout_white_bg {
  background-color: var(--white);
}

.layout_black_bg {
  background-color: var(--blackColor);
}

.layout_padding {
  padding: 2rem 6rem;
}

.layout_padding_full_width {
  padding: 0px 0rem 2rem;
}

.layout_loader_container {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.gif_loader_container {
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: calc(100vh - 117px);
  position: absolute;
  top: 60px;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

@media screen and (max-width: 576px) {
  .layout_padding {
    padding: 2rem 2rem !important;
  }
}
