.afp_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.afp_header_text {
  color: var(--whiteBoldText) !important;
  text-align: left !important;
  font-weight: 100 !important;
  align-self: start;
}

.afp_reset_link {
  margin-bottom: 30px;
}
