.sidebar_container {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  background-color: var(--white);
  z-index: 1000;
  border: solid 1px var(--grayColor);
  transition: 0.3s cubic-bezier(0.36, -0.01, 0, 0.77);
  display: block;
  width: 0px;
}

.visible {
  width: 60% !important;
}

.s_profile_img_container {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: var(--grayColor);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 20px auto 10px;
}

.s_profile_text {
  font-size: 26px !important;
}

.s_edit_icon {
  cursor: pointer;
  position: absolute;
  right: 0px;
  bottom: -5px;
  background-color: var(--white);
  height: 35px;
  width: 35px;
  border-radius: 50%;
  border: solid 1px var(--placeholderTextColor);
  display: flex;
  justify-content: center;
  align-items: center;
}
.s_tabs_container {
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: start;
}
.s_tab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  width: 200px;
}

.s_tab_bg_filler {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  padding-left: 23px;
}

.selected_tab {
  background-color: var(--grayColor);
}

.sidebar_menu_icon {
  width: 24px;
  height: 24px;
}