.card_wrapper_container {
  width: 100%;
  max-width: 544px;
  background-color: var(--whiteBoldText);
  border-radius: 10px;
  padding: 20px;
  position: relative;
  margin-top: 45px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.card_wrapper_img_container {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  left: 49%;
  top: 10%;
  transform: translate(-50%, -100%);
}

.children_wrapper_div {
  margin-top: 28px;
}
