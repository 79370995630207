.lt_title {
  color: var(--whiteBoldText) !important;
}

.lt_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.lt_map_container {
  position: relative;
  height: calc(100vh - 300px);
}

.lt_tracking_icon {
  background-color: var(--blackColor);
  position: absolute;
  bottom: 38px;
  left: 38px;
  width: 70px;
  height: 70px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.lt_icon_text {
  color: var(--whiteBoldText) !important;
}
