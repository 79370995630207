.cm_body {
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0px 6px 6px 0px #00000040;
  display: flex;
  flex-direction: column;
  padding: 15px !important;
  position: relative;
}

.cm_cross_icon {
  position: absolute;
  top: -20px;
  left: -20px;
  border-radius: 50%;
  cursor: pointer;
}

.cm_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .modal-content {
    max-width: 50%;
    margin: 0 auto;
  }
}

.cm_text {
  text-align: center !important;
}

.cm_confirm_btn {
  color: var(--whiteBoldText) !important;
  background-color: var(--blackColor) !important;
}
