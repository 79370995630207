.ci_container {
  width: 100%;
  position: relative;
}

.ci_label {
  color: var(--whiteBoldText);
}

.ci_input {
  padding: 0px 10px;
  width: 100%;
  height: 45px;
  border-radius: 3px;
  border: 1px solid var(--placeholderTextColor);
  padding-right: 30px;
  background-color: var(--white);
}

.ci_input:focus {
  border: 1px solid var(--placeholderTextColor);
}

.ci_input input {
  width: 100%;
  box-sizing: border-box;
}

.ci_input ::placeholder {
  font-family: Circular TT Book;
  font-size: 14px;
  font-weight: 450;
  line-height: 18px;
  text-align: left !important;
  color: var(--placeholderTextColor) !important;
}

.ci_iconHide {
  position: absolute;
  top: 52%;
  right: 15px;
  transform: translateY(-50%);
  cursor: pointer;
}

.custominput_icon {
  color: var(--redColor);
}

.ci_placeholder_text {
  position: absolute;
  top: 38px;
  left: 15px;
  color: var(--placeholderTextColor) !important;
}

.ci_input:focus {
  outline: none;
}

.ci_input:disabled {
  cursor: not-allowed;
}

.ci_input_textarea {
  resize: none;
  height: 80px;
}